import { useState } from "react";

interface Props {
  defaultImage: string;
  hoverImage: string;
}

export function ImageHover({ defaultImage, hoverImage }: Props) {
  const [hover, setHover] = useState(false);
  return (
    <div
      onMouseOver={() => setHover(true)}
      onMouseOut={() => setHover(false)}
      className="cursor-pointer w-[100px] flex items-center justify-center"
    >
      {hover ? (
        <img
          alt="MM"
          src={`/images/${hoverImage}`}
          className="lg:w-auto w-2/3"
        />
      ) : (
        <img
          alt="MM"
          src={`/images/${defaultImage}`}
          className="lg:w-auto w-2/3"
        />
      )}
    </div>
  );
}
