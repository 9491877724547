export function DevelopCredits() {
  return (
    <div className="flex items-center space-x-[5px]">
      <div className="text-white">Developed By</div>
      <a
        href="https://cmooredev.com"
        target="_blank"
        rel="noreferrer"
        className="text-red-500 hover:text-red-900"
      >
        Charles Moore Development
      </a>
    </div>
  );
}
