import React from "react";
import "./App.css";
import { GoldenAgeDisplay } from "./components/GoldenAgeDisplay";
import { DevelopCredits } from "./components/DevelopCredits";

function App() {
  return (
    <div className="flex flex-col items-center justify-center w-screen h-screen">
      <GoldenAgeDisplay />
      <DevelopCredits />
    </div>
  );
}

export default App;
