import { ImageHover } from "../ImageHover";

export function GoldenAgeDisplay() {
  return (
    <div className="relative">
      <div className="lg:hidden text-white flex justify-center italic text-red-900">
        Tap each image to see the animation
      </div>
      <img alt="MM" src="/images/bkgd3.gif" />
      <div className="absolute w-[100px] top-[40px] left-[20px] lg:top-[170px] lg:left-[90px] z-[1000]">
        <ImageHover
          defaultImage="mm2_r2_c2.gif"
          hoverImage="mm2_over_r2_c2.gif"
        />
        <ImageHover
          defaultImage="mm2_r3_c2.gif"
          hoverImage="mm2_over_r3_c2.gif"
        />
        <ImageHover
          defaultImage="mm2_r4_c2.gif"
          hoverImage="mm2_over_r4_c2.gif"
        />
        <ImageHover
          defaultImage="mm2_r5_c2.gif"
          hoverImage="mm2_over_r5_c2.gif"
        />
      </div>
      <div className="absolute inset-0 flex items-center justify-center">
        <img
          alt="Coming Soon"
          src="/images/art_soon.gif"
          className="lg:w-auto w-1/2"
        />
      </div>
      <div className="absolute w-[100px] top-[40px] right-[20px] lg:top-[170px] lg:right-[90px] z-[1000]">
        <ImageHover
          defaultImage="mm2_r2_c4.gif"
          hoverImage="mm2_over_r2_c4.gif"
        />
        <ImageHover
          defaultImage="mm2_r3_c4.gif"
          hoverImage="mm2_over_r3_c4.gif"
        />
        <ImageHover
          defaultImage="mm2_r4_c4.gif"
          hoverImage="mm2_over_r4_c4.gif"
        />
        <ImageHover
          defaultImage="mm2_r5_c4.gif"
          hoverImage="mm2_over_r5_c4.gif"
        />
      </div>
    </div>
  );
}
